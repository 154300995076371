import appstore from "../../appStore";
import { ServiceCheckStatus } from "../Booking/BookingEntities";
import { CheckFutureBookingTime, GetBookInAdvanceDays } from "../Booking/CheckBookingTime";
import { BookingFormKind, UILayoutMode } from "./UILogicControlEntities";
import { IsFixedPriceBooking, IsPaymentAndServiceValidForPriceGuarantee } from "../Payment/PaymentHandler";
import { OptionalUI } from "../Booking/OptionalParts/OptionalUI";

/**
 * Controls the enabled state of the "Book Now" button in the booking form.
 */
export function AreBookingRequisitesReady() : boolean {
    
    const appState = appstore.getState();
    const { IsSelectedVehicleServiceable, IsInvalidConditionForSatss, ActiveBookingForm } = appState.uiLogicControl.BookingForm;
    const { Dropoff, BookingTimeV2, PickupServiceCheck, PaymentOption, DeliveryOption } = appState.booking;

    // Passenger booking requires a passenger contact
    if (ActiveBookingForm === BookingFormKind.PassengerBooking) {

        const contact = appState.booking.Pickup.Contact;

        // name
        if (!contact.Name) return false;

        // number
        if (!contact.Phone) return false;
        if (!appState.uiLogicControl.BookingForm.Pickup.ContactNumber.IsValid) return false;
    }
    
    // Validate pick-up address
    if (PickupServiceCheck.status !== ServiceCheckStatus.KnownGood) return false;

    // Drop off address is mandatory for all bookings
    if (!Dropoff.Address) return false;

    // Validate future date & time only for future booking;
    if (!BookingTimeV2.IsImmediate) {
        const bookInAdvanceDays = GetBookInAdvanceDays(appState.authentication.UserProfile);
        const result = CheckFutureBookingTime(BookingTimeV2.RequestedDate, bookInAdvanceDays);

        if (!result.IsValid) return false;
    }

    // Contact number has no error 
    if (appState.verification.UserContactNumberInfo.ErrorMessage) return false;

    // Vehicles list is empty (i.e.: loading vehicles from the API failed in V2 API)
    if (appState.condition.ConditionList.length === 0) return false;

    // Validate selected vehicle
    if (!IsSelectedVehicleServiceable) return false;

    const IsBookingOnAccount = appState.booking.IsOnAccount;
    
    // Delivery Option is mandatory when it is shown
    if (OptionalUI.DeliveryOption(appState) && (DeliveryOption === null)) return false;

    const AccountSelectedForBooking = appState.booking.AccountData;

    // Validate account details when booking on accounts i.e. Toggle is ON
    if (IsBookingOnAccount && !AccountSelectedForBooking) return false;

    // Validate only for account bookings
    if (AccountSelectedForBooking) {

        // Extract account booking payload
        const { OrderNumber, FileNumber, SelectedAccount } = AccountSelectedForBooking;

        // For DVA accounts booking, Provider Number (OrderNumber) and File Number are mandatory fields
        if (SelectedAccount.IsDVA && (!OrderNumber || !FileNumber)) {
            return false;
        }
        else {
            // If OrderNumber field is required, then OrderNumber has to be not null
            if (SelectedAccount.IsOrderNumberRequired) {
                return OrderNumber?.trim() != "";                
            }
        }
    }
    else {

        // Validate payment method
        if (!PaymentOption) return false;

        if (!IsPaymentAndServiceValidForPriceGuarantee(appState)) return false;

        // Payment method has error
        if (appState.payment.PaymentCardError) return false;

        // Apple Pay is selected but can't pay for the trip with Apple Pay. e.g: Apple Pay is selected while the Fixed fare toggle is on, then turn the toggle off.
        if (appState.booking.PaymentOption?.Type == "ApplePay" && !IsFixedPriceBooking()) return false;

        // Google Pay is selected but can't pay for the trip with Google Pay. e.g: Google Pay is selected while the Fixed fare toggle is on, then turn the toggle off.
        if (appState.booking.PaymentOption?.Type == "GooglePay" && !IsFixedPriceBooking()) return false;
    }

    // SATSS is selected as the payment method and the selected condition doesn't support SATSS.
    if (IsInvalidConditionForSatss) return false;

    // Email for payment receipt is required when it is shown
    if (OptionalUI.EmailForPaymentReceipt(appState) && !appState.GuestPayment.EmailForReceipt) return false;
    
    return true;
};

/**
 * Helper function to decide 
 */
export function UnderMobileMode() : boolean {

    const { LayoutMode } = appstore.getState().uiLogicControl;

    return LayoutMode === UILayoutMode.Mobile;
}